import React from 'react';
import { Box, Container, IconButton, Stack, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const Footer: React.FC = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        position: 'fixed',
        bottom: 0,
        width: '100%',
        py: 2,
        backgroundColor: 'background.paper',
        borderTop: 1,
        borderColor: 'divider',
        zIndex: 1000,
        backdropFilter: 'blur(10px)',
      }}
    >
      <Container maxWidth="lg">
        <Stack 
          direction="row" 
          spacing={2} 
          justifyContent="center" 
          alignItems="center"
        >
          <Tooltip title="Email">
            <IconButton 
              color="inherit" 
              sx={{ 
                '&:hover': { 
                  transform: 'scale(1.1)',
                  color: 'primary.main',
                  backgroundColor: 'transparent'
                },
                transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out'
              }}
            >
              <EmailIcon />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Instagram">
            <IconButton 
              color="inherit"
              sx={{ 
                '&:hover': { 
                  transform: 'scale(1.1)',
                  color: '#E1306C',
                  backgroundColor: 'transparent'
                },
                transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out'
              }}
            >
              <InstagramIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="TikTok">
            <IconButton 
              color="inherit"
              sx={{ 
                '&:hover': { 
                  transform: 'scale(1.1)',
                  color: '#f05a5a',
                  backgroundColor: 'transparent'
                },
                transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out'
              }}
            >
              <MusicNoteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;