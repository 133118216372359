import React from 'react';
import { Typography, Grid, Card, CardContent, CardHeader, Container } from '@mui/material';

const features = [
  { 
    title: 'Music Sharing', 
    description: 'Share tracks and albums directly with friends or to your profile feed.' 
  },
  { 
    title: 'Collaborative Playlists', 
    description: 'Create playlists with friends and discover new music together.' 
  },
  { 
    title: 'Spotify Integration', 
    description: 'Seamlessly connect with your Spotify account to access your music library.' 
  },
  { 
    title: 'Social Feed', 
    description: 'See what your friends are listening to and discover new tracks.' 
  },
  { 
    title: 'Music Profile', 
    description: 'Showcase your music taste and favorite tracks on your profile.' 
  },
  { 
    title: 'Friend System', 
    description: 'Connect with friends and follow other music enthusiasts.' 
  },
];

const Features = () => {
  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom>
        Features
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardHeader title={feature.title} />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;