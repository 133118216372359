import React from 'react';
import { Typography, Box } from '@mui/material';

const Contact = () => {
  return (
    <Box sx={{ 
      maxWidth: 400, 
      mx: 'auto',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Contact Us ... coming soon
      </Typography>
    </Box>
  );
};

export default Contact;