import React from 'react';
import { Typography, Button, Box, Grid, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Home = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box sx={{ textAlign: 'center', py: 8 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Soundcred
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom sx={{ mb: 4 }}>
          Discover and share music with friends in a whole new way
        </Typography>
        
        {/* App Store & TestFlight Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 8 }}>
          <Button 
            variant="contained" 
            color="primary" 
            size="large"
            href="#" // Add TestFlight link when available
          >
            Join TestFlight Beta
          </Button>
          <Button 
            variant="outlined" 
            color="primary" 
            size="large"
            href="#" // Add App Store link when available
          >
            Coming to App Store
          </Button>
        </Box>

        {/* App Screenshots Section */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h4" gutterBottom>
            Share Your Music Journey
          </Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
              {/* Replace with actual screenshot */}
              <Box sx={{ 
                height: 500,
                backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eee',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                Screenshot 1
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                height: 500,
                backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eee',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                Screenshot 2
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                height: 500,
                backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eee',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                Screenshot 3
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Key Features Section */}
        <Box>
          <Typography variant="h4" gutterBottom>
            Key Features
          </Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Share Music
              </Typography>
              <Typography>
                Share your favorite tracks and albums directly with friends
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Collaborative Playlists
              </Typography>
              <Typography>
                Create and collaborate on playlists with your friends
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Spotify Integration
              </Typography>
              <Typography>
                Seamlessly connected with your Spotify account
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;