import React from 'react';
import { Typography, Paper, Box, Container } from '@mui/material';

const About = () => {
  return (
    <Container sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          About Soundcred
        </Typography>
        <Typography variant="body1" paragraph>
          Soundcred is a social music discovery platform that makes sharing and discovering music with friends easier than ever. Built with the music lover in mind, our iOS app connects with Spotify to create a seamless music sharing experience.
        </Typography>
        <Typography variant="body1" paragraph>
          Our mission is to enhance the way people discover and share music, making it more social and engaging. We believe that the best music recommendations come from friends, not algorithms.
        </Typography>
        <Typography variant="body1">
          With Soundcred, you can:
        </Typography>
        <Box component="ul" sx={{ mt: 2 }}>
          <Typography component="li">Share tracks and albums with friends instantly</Typography>
          <Typography component="li">Create collaborative playlists</Typography>
          <Typography component="li">Discover what your friends are listening to</Typography>
          <Typography component="li">Connect with other music enthusiasts</Typography>
          <Typography component="li">Integrate seamlessly with your Spotify account</Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default About;